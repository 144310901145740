import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "boka-demo-icon.png" }) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <header className="header">
      <div className="header__content">
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              textDecoration: `none`,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Img fixed={data.placeholderImage.childImageSharp.fixed} />
            <span
              style={{
                marginLeft: "10px",
                fontSize: "24px",
                // color: "#3377FF",
                color: "#fff",
              }}
            >
              Boka<span style={{ color: "#002A80" }}>demo</span>
            </span>
          </Link>
        </h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
