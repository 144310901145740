import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

const LegalPage = () => (
  <Layout>
    <SEO title="Legal" />
    <Header siteTitle="Boka demo nu" />
    {/** {data.site.siteMetadata.title} */}
    <div style={{ maxWidth: "960px", margin: "0 auto" }}>
      <h1>Bokademo.nu Privacy Policy</h1>
      <p>
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit, or when register for a demo
        or free trial on bokademo.nu (the “Site”).
      </p>
      <h2>1. PERSONAL INFORMATION WE COLLECT</h2>
      <p>
        We receive and store any information you enter on our Site or give us in
        any other way, this may include information that can identify you
        (“personally identifiable information”). We may also request information
        about your preferences and usage of technology products and services. We
        also collect certain information automatically when you use or visit our
        Site.
      </p>
      <p>Information we collect from:</p>
      <h3>Visitors of our Site</h3>
      <p>
        When you visit the Site, we automatically collect certain information
        about your device, including information about your web browser, IP
        address, time zone, and some of the cookies that are installed on your
        device. Additionally, as you browse the Site, we collect information
        about the individual web pages or products that you view, what websites
        or search terms referred you to the Site, and information about how you
        interact with the Site. We refer to this automatically-collected
        information as “Device Information.”
      </p>
      <p>We collect Device Information using the following technologies:</p>
      <ul>
        <li>
          “Log files” track actions occurring on the Site, and collect data
          including your IP address, browser type, Internet service provider,
          referring/exit pages, and date/time stamps.
        </li>
        <li>
          “Web beacons”, “tags,” and “pixels” are electronic files used to
          record information about how you browse the Site.
        </li>
        <li>
          “Google Analytics” to view web site data and analyze the behaviour on
          the site. You can read about how Google uses your Personal Information
          here:{" "}
          <a href="https://www.google.com/intl/en/policies/privacy/">
            https://www.google.com/intl/en/policies/privacy/
          </a>
          . You can also opt-out of Google Analytics here:{" "}
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
          .
        </li>
      </ul>
      <h3>Register for a demo or free trial</h3>
      <p>
        To use some of the features of our Site, certain information may be
        required. For example, your business email address, telephone number,
        company information is necessary if you want to request for a demo. When
        we talk about “Personal Information” in this Privacy Policy, we are
        talking both about Device Information.
      </p>
      <h2>2. HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>
      <p>
        Bokademo.nu, which is owned by BusinessWith AB, uses the information we
        collect about you for registration and to manage your account, to
        communicate with you, and to connect you with the providers of software
        products and services listed on our Site. We also use your information
        for analytics, for marketing and advertising, and for legal reasons.
      </p>
      <p>
        We use the Device Information that we collect to help us screen for
        potential risk and fraud (in particular, your IP address), and more
        generally to improve and optimize our Site (for example, by generating
        analytics about how our customers browse and interact with the Site, and
        to assess the success of our marketing and advertising campaigns).
      </p>
      <p>Additionally, we also use the information:</p>
      <ul>
        <li>to resolve disputes or troubleshoot problems;</li>
        <li>to prevent prohibited or illegal activities;</li>
        <li>to enforce our Terms of Use, Privacy Policy, and cookie policy;</li>
        <li>
          to share your information with the providers of listed products or
          services when you request a demo or free trial of a certain listed
          product or service;
        </li>
        <li>
          When it is necessary to fulfil our legitimate interests:
          <ul>
            <li>to solicit information from you, including through surveys;</li>
            <li>
              to share aggregated information with providers of listed products
              or services and other licensees of our research and report
              offerings;
            </li>
            <li>
              to measure interest in and improve our offerings and Site;
              including to provide information about Bokademo.nu and invitations
              from BusinessWith Sweden AB.
            </li>
            <li>
              to notify you about special offers available from us or our
              partners, or from the providers of products and services listed on
              our Site, that may be of interest to you;
            </li>
          </ul>
        </li>
      </ul>
      <h2>3. SHARING YOUR PERSONAL INFORMATION</h2>
      <p>
        We share your Personal Information with third parties when you request
        for a demo or free trial of their products, as described above. For
        example, we use Google Analytics to help us understand how our visitors
        use the Site --you can read more about how Google uses your Personal
        Information here:{" "}
        <a href="https://www.google.com/intl/en/policies/privacy/">
          https://www.google.com/intl/en/policies/privacy/
        </a>
        . You can also opt-out of Google Analytics here:
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>
        .
      </p>
      <p>
        BusinessWith AB may share some of your information with providers of
        products and services listed on our Site, third-party vendors who
        provide services or functions on our behalf, business partners, social
        media networks and the public in general.
      </p>
      <p>This is how we share your information with the following entities:</p>
      <ol>
        <li>
          We share your contact information and company name with providers of
          software products and services listed on our Site when you expressly
          request to connect by requesting more information, free trial, or a
          demo of their product.
        </li>
        <li>
          We share aggregated data with our business partners for analytics and
          advertising purposes.
        </li>
        <li>
          Your information is also accessible to our third-party vendors who
          provide services/functions on our behalf, such as business analytics,
          customer service, marketing, distribution of surveys or promotion
          programs, payment processing, and fraud prevention. These third-party
          vendors collect and use your information on behalf of BusinessWith AB,
          for specific purposes, and are not permitted to share or use the
          information for any other purpose.
        </li>
        <li>
          Business partners with whom we may offer products or services in
          conjunction. Using these types of products or services is optional.
          You can tell when a business partner is involved in a product or
          service, because their name will appear either with ours or
          separately. If you choose to access these optional services, your
          information will be shared with the relevant business partner.
        </li>
      </ol>
      <p>
        Finally, we may also share your Personal Information to comply with
        applicable laws and regulations, to respond to a subpoena, search
        warrant or other lawful request for information we receive, or to
        otherwise protect our rights.
      </p>
      <p>
        As described above, we use your Personal Information to provide you with
        targeted advertisements or marketing communications we believe may be of
        interest to you. For more information about how targeted advertising
        works, you can visit the Network Advertising Initiative’s (“NAI”)
        educational page at{" "}
        <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
        </a>
        .
      </p>
      <p>You can opt out of targeted advertising by:</p>
      <ul>
        <li>
          FACEBOOK -{" "}
          <a href="https://www.facebook.com/settings/?tab=ads">
            https://www.facebook.com/settings/?tab=ads
          </a>
        </li>
        <li>
          GOOGLE -{" "}
          <a href="https://www.google.com/settings/ads/anonymous">
            https://www.google.com/settings/ads/anonymous
          </a>
        </li>
        <li>
          BING -
          <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          </a>
        </li>
      </ul>
      <p>
        Additionally, you can opt out of some of these services by visiting the
        Digital Advertising Alliance’s opt-out portal at:{" "}
        <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>.
      </p>
      <h4>Do not track</h4>
      <p>
        Please note that we do not alter our Site’s data collection and use
        practices when we see a Do Not Track signal from your browser.
      </p>
      <h2>4. YOUR EUROPEAN PRIVACY RIGHTS UNDER GDPR</h2>
      <p>
        If you are a European resident, you have the right to access personal
        information we hold about you and to ask that your personal information
        be corrected, updated, or deleted. If you would like to exercise this
        right, please contact us through{" "}
        <a href="mailto:info@businesswith.se">info@businesswith.se</a>, also see
        contact information below.
      </p>
      <p>
        Privacy rights under the EU General Data Protection Regulation include:
      </p>
      <p>
        Transparency and the right to information. Through this policy we
        explain how we use and share your information. However, if you have
        questions or concerns you can contact us any time.
      </p>
      <p>
        Right of access, objection, restriction of processing, erasure, and
        portability. You also have the right to withdraw your consent at any
        time when we process your personal data based on your consent. To
        exercise these rights, please contact us.
      </p>
      <p>
        Right to opt-out to direct marketing. You have the right to opt-out at
        any time to receiving marketing materials from us by following the
        opt-out instructions in our commercial emails, by contacting us, or by
        adjusting your preferences under your Profile Details on the Site.
      </p>
      <p>
        Please note that we reserve the right to send you other communications,
        including service announcements and administrative messages relating to
        your BusinessWith account, without offering you the opportunity to opt
        out of receiving them.
      </p>
      <p>
        Right not to be subject to an automated decision, including profiling.
        We do not make automated decisions using your personal data that may
        negatively impact you. We use cookies and similar technologies on our
        Site to personalize your experience on the Site and recommending
        software products and services that may be of interest to you. For
        additional information or to opt-out see our Cookie Policy.
      </p>
      <p>
        Right to lodge a complaint with a supervisory authority. If you consider
        that the processing of your personal data infringes your privacy rights
        according to the General Data Protection Regulation, you have the right
        to lodge a complaint with a supervisory authority, in the member state
        of your habitual residence, place of work, or place of the alleged
        infringement. Contact details for the EU data protection authorities can
        be found at{" "}
        <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
      </p>
      <h3>CHANGES</h3>
      <p>
        We may update this privacy policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal or
        regulatory reasons.
      </p>
      <h3>CONTACT US</h3>
      <p>
        For more information about our privacy practices, if you have questions,
        or if you would like to make a complaint, please contact us by e-mail at{" "}
        <a href="mailto:info@businesswith.se">info@businesswith.se</a> or by
        mail using the details provided below:
      </p>
      <p>
        BusinessWith Sweden AB
        <br />
        Kungsholmsgatan 46
        <br />
        112 27 Stockholm
        <br />
        Sweden
      </p>
    </div>
  </Layout>
)

export default LegalPage
